{
  "auth/claims-too-large": "The claims payload provided to setCustomUserClaims() exceeds the maximum allowed size per token (1000 bytes).",
  "auth/email-already-exists": "The email address is already in use by another account.",
  "auth/id-token-expired": "The provided ID token is expired.",
  "auth/id-token-revoked": "The ID token is revoked.",
  "auth/insufficient-permission": "Insufficient permissions",
  "auth/internal-error": "The Authentication server encountered an unexpected error while trying to process the request",
  "auth/invalid-argument": "An invalid argument was provided to an Authentication method. The error message should contain additional information.",
  "auth/invalid-claims": "The custom claim attributes provided to setCustomUserClaims() are invalid.",
  "auth/invalid-continue-uri": "The continue URL provided in the request is invalid.",
  "auth/invalid-creation-time": "The creation time must be a valid UTC date string.",
  "auth/invalid-disabled-field": "The disabled field must be a boolean.",
  "auth/invalid-display-name": "The displayName field must be a valid string.",
  "auth/invalid-dynamic-link-domain": "The provided dynamic link domain is not configured or authorized for the current project.",
  "auth/invalid-email": "The email address is not valid.",
  "auth/invalid-email-verified": "The email address is already in use by another account.",
  "auth/invalid-hash-algorithm": "The hash algorithm must match one of the strings in the list of supported algorithms.",
  "auth/invalid-hash-block-size": "The hash block size must be a valid number.",
  "auth/invalid-hash-derived-key-length": "The hash derived key length must be a valid number.",
  "auth/invalid-hash-key": "The hash key must a valid byte buffer.",
  "auth/invalid-hash-memory-cost": "The hash memory cost must be a valid number.",
  "auth/invalid-hash-parallelization": "The hash parallelization must be a valid number.",
  "auth/invalid-hash-rounds": "The hash rounds must be a valid number.",
  "auth/invalid-hash-salt-separator": "The salt separator must be a valid byte buffer.",
  "auth/invalid-last-sign-in-time": "The last sign-in time must be a valid UTC date string.",
  "auth/invalid-page-token": "The page token must be a valid non-empty string.",
  "auth/invalid-password": "The password must be a string with at least six characters.",
  "auth/invalid-password-hash": "The password hash must be a valid byte buffer.",
  "auth/invalid-password-salt": "The password salt must be a valid byte buffer.",
  "auth/invalid-phone-number": "The phone number must be a non-empty E.164 standard compliant identifier string.",
  "auth/invalid-photo-url": "The photoURL field must be a valid URL.",
  "auth/invalid-provider-data": "The providerData must be a valid array of UserInfo objects.",
  "auth/invalid-provider-id": "The providerId must be a valid supported provider identifier string.",
  "auth/invalid-session-cookie-duration": "The session cookie duration must be a valid number in milliseconds between 5 minutes and 2 weeks.",
  "auth/invalid-uid": "The uid must be a non-empty string with at most 128 characters.",
  "auth/invalid-user-import": "The user record to import is invalid.",
  "auth/maximum-user-count-exceeded": "The maximum allowed number of users to import has been exceeded.",
  "auth/missing-android-pkg-name": "An Android Package Name must be provided if the Android App is required to be installed.",
  "auth/missing-continue-uri": "A continue URL must be provided in the request.",
  "auth/missing-hash-algorithm": "The hash algorithm must be provided for password hashing.",
  "auth/missing-ios-bundle-id": "An iOS Bundle ID must be provided if an App Store ID is provided.",
  "auth/missing-uid": "A uid identifier is required for the current operation.",
  "auth/operation-not-allowed": "The given sign-in provider is disabled for this Firebase project. Enable it in the Firebase console, under the sign-in method tab of the Auth section.",
  "auth/phone-number-already-exists": "The phone number is already in use by another account.",
  "auth/project-not-found": "No Firebase project was found for the provided credential.",
  "auth/reserved-claims": "One or more custom claims provided to setCustomUserClaims() are reserved.",
  "auth/session-cookie-expired": "The session cookie is expired.",
  "auth/session-cookie-revoked": "The session cookie is revoked.",
  "auth/uid-already-exists": "The user with the provided uid already exists.",
  "auth/unauthorized-continue-uri": "The domain of the continue URL is not whitelisted. Whitelist the domain in the Firebase console.",
  "auth/user-not-found": "The email or password is invalid",
  "auth/weak-password": "The password must be a string with at least 6 characters.",
  "auth/web-storage-unsupported": "This browser is not supported or 3rd party cookies and data may be disabled.",
  "auth/invalid-credential": "The supplied auth credential is malformed or has expired.",
  "auth/wrong-password": "The email or password is invalid",
  "auth/invalid-verification-code": "The phone verification code is invalid. Please resend the verification code to try again.",
  "auth/invalid-verification-id": "The verification ID is invalid.",
  "auth/missing-verification-code": "The phone auth credential was created with an empty SMS verification code.",
  "auth/missing-verification-id": "The phone auth credential was created with an empty verification ID.",
  "auth/app-deleted": "The Firebase app was deleted.",
  "auth/app-not-authorized": "The app identified by the domain where it's hosted is not authorized to use Firebase Authentication with the provided API key.",
  "auth/argument-error": "An internal error has occurred.",
  "auth/captcha-check-failed": "The reCAPTCHA response token provided is either invalid, expired, already used or the domain associated with it does not match the list of whitelisted domains.",
  "auth/invalid-api-key": "Your API key is invalid, please check you have copied it correctly.",
  "auth/invalid-tenant-id": "The Auth instance's tenant ID is invalid.",
  "auth/network-request-failed": "A network error (such as timeout, interrupted connection or unreachable host) has occurred.",
  "auth/operation-not-supported-in-this-environment": "This operation is not supported in the environment this application is running on. \"location.protocol\" must be http, https or chrome-extension and web storage must be enabled.",
  "auth/requires-recent-login": "This operation is sensitive and requires recent authentication. Log in again before retrying this request.",
  "auth/too-many-requests": "We have blocked all requests from this device due to unusual activity. Try again later.",
  "auth/user-token-expired": "The user token has expired.",
  "auth/quota-exceeded": "The project's quota for this operation has been exceeded."
}
