import { ApplicationConfig, provideZoneChangeDetection } from '@angular/core';
import { provideRouter, withHashLocation } from '@angular/router';

import { routes } from './app.routes';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { authInterceptor, tokenInterceptor } from './services/http.service';
import { provideNgxErrorsConfig } from '@ngspot/ngx-errors';

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes, withHashLocation()),
    provideHttpClient(withInterceptors([authInterceptor, tokenInterceptor])),
    // provideFirebaseApp(() => initializeApp(environment.firebaseConf)),
    // provideAuth(() => getAuth()),
    provideNgxErrorsConfig({
      showErrorsWhenInput: 'touched',
    }),
  ],
};
