import { EventEmitter, inject, Injectable } from '@angular/core';
import { State } from '../store';
import dayjs from 'dayjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ErrorsService {
  authError$ = new EventEmitter<any>();
  shouldFetchProfile$ = new EventEmitter<any>();
  apiError$ = new EventEmitter<any>();
  authPrompt$ = new EventEmitter<string>();
  state = inject(State);

  isWithinSessionLifetime() {
    const lastCall = this.state.store().auth.lastApiResponse;
    return (
      lastCall !== null &&
      dayjs().diff(dayjs(lastCall), 'minutes') <
        environment.SESSION_LIFETIME_MINUTES
    );
  }
}
