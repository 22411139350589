import { inject, Injectable } from '@angular/core';
import { State } from './state';
import { Partner } from '../services/partners.service';

@Injectable({
  providedIn: 'root',
})
export class Getters {
  private _stateService = inject(State);
  theme = (): Partner | null => {
    return this._stateService.store().tenant;
  };
  auth = () => {
    return this._stateService.store().auth;
  };
}
