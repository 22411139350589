{
  "name": "hp-mtek-admin-portal-v3",
  "version": "v1.0.0-staging",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "build": "ng build",
    "watch": "ng build --watch --configuration development",
    "test": "ng test",
    "test:ci": "ng test --watch=false --browsers=ChromeHeadless --code-coverage",
    "e2e": "ng e2e",
    "e2e:ci": "ng e2e --watch=false",
    "e2e:coverage": "npx nyc report --reporter=lcov --reporter=text-summary",
    "e2e:run": "ng e2e --watch=false",
    "e2e:headless": "ng e2e --watch=false",
    "cy:run:report": "nyc --reporter=html cypress run",
    "analyze": "ng build --stats-json",
    "cypress:open": "cypress open",
    "cypress:run": "cypress run",
    "lint": "ng lint",
    "translate": "ng extract-i18n --out-file=messages.json --output-path src/locale --format=json"
  },
  "private": true,
  "dependencies": {
    "@angular/cdk": "^18.1.2",
    "@angular/common": "^18.1.2",
    "@angular/compiler": "^18.1.2",
    "@angular/core": "^18.1.2",
    "@angular/fire": "^18.0.1",
    "@angular/forms": "^18.1.2",
    "@angular/platform-browser": "^18.1.2",
    "@angular/platform-browser-dynamic": "^18.1.2",
    "@angular/router": "^18.1.2",
    "chart.js": "^4.4.3",
    "config": "^3.3.12",
    "mapbox-gl": "^3.9.2",
    "ngx-extended-pdf-viewer": "^21.4.5",
    "rxjs": "~7.8.0",
    "tslib": "^2.3.0",
    "zone.js": "~0.14.3"
  },
  "devDependencies": {
    "@angular-builders/custom-webpack": "^18.0.0",
    "@angular-devkit/build-angular": "^18.1.2",
    "@angular/animations": "^18.1.2",
    "@angular/cli": "^18.1.2",
    "@angular/compiler-cli": "^18.1.2",
    "@angular/localize": "^18.1.2",
    "@cypress/code-coverage": "^3.13.7",
    "@cypress/schematic": "^2.5.1",
    "@istanbuljs/nyc-config-typescript": "^1.0.2",
    "@jsdevtools/coverage-istanbul-loader": "^3.0.5",
    "@ng-select/ng-select": "^13.4.1",
    "@ngspot/ngx-errors": "^4.0.0",
    "@types/color": "^3.0.6",
    "@types/file-saver": "^2.0.7",
    "@types/jasmine": "~5.1.0",
    "@types/uuid": "^10.0.0",
    "angular-eslint": "18.0.1",
    "autoprefixer": "^10.4.19",
    "babel-loader": "^9.2.1",
    "babel-plugin-istanbul": "^7.0.0",
    "choices.js": "^10.2.0",
    "color": "^4.2.3",
    "cypress": "^13.16.0",
    "dayjs": "^1.11.11",
    "eslint": "^9.7.0",
    "eslint-config-prettier": "^9.1.0",
    "file-saver": "^2.0.5",
    "flatpickr": "^4.6.13",
    "flowbite": "^2.3.0",
    "flowbite-datepicker": "^1.2.7",
    "istanbul-lib-coverage": "^3.2.2",
    "istanbul-lib-instrument": "^6.0.3",
    "istanbul-lib-report": "^3.0.1",
    "istanbul-lib-source-maps": "^5.0.6",
    "istanbul-reports": "^3.1.7",
    "jasmine-core": "~5.1.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "moment": "^2.30.1",
    "ng-qrcode": "^18.0.0",
    "ngx-build-plus": "^18.0.0",
    "ngx-sonner": "^2.0.1",
    "nyc": "^17.1.0",
    "path": "^0.12.7",
    "postcss": "^8.4.38",
    "prettier": "^3.3.3",
    "tailwindcss": "^3.4.3",
    "tslint": "^6.1.3",
    "tslint-config-prettier": "^1.18.0",
    "typescript": "~5.4.2",
    "typescript-eslint": "8.0.0-alpha.20",
    "uuid": "^10.0.0",
    "webpack": "^5.96.1",
    "webpack-bundle-analyzer": "^4.10.2",
    "xlsx": "https://cdn.sheetjs.com/xlsx-latest/xlsx-latest.tgz"
  },
  "nyc": {
    "report-dir": "coverage"
  }
}
