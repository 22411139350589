import { Routes } from '@angular/router';
import { BlankComponent } from './layouts/blank/blank.component';

export const routes: Routes = [
  // Sidebar Layout
  {
    path: '',
    component: BlankComponent,
    loadChildren: () =>
      import('./pages/portal.module').then((m) => m.PortalModule),
  },

  // Full screen layout

  // No Layout at all
  { path: '**', redirectTo: '' },
];
