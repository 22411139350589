import { Component, inject, OnInit, Renderer2 } from '@angular/core';
import { EventType, Router, RouterOutlet } from '@angular/router';
import { Actions, State } from './store';
import { CommonModule, NgClass, NgForOf, NgIf } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { ThemeService } from './services/theme.service';
import { AuthService } from './services/auth.service';
import { LoadingComponent } from './library/components/loading/loading.component';
import { LayoutService } from './services/layout.service';
import { ToastService } from './services/toast.service';
import { HttpService } from './services/http.service';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import dayjs from 'dayjs';
import { HttpCancelService } from './services/http-cancel.service';
import { ErrorsService } from './services/errors.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css',
})
export class AppComponent implements OnInit {
  title = 'Admin Portal';
  state = inject(State);
  theme = inject(ThemeService);
  actions = inject(Actions);
  layout = inject(LayoutService);
  toasts = inject(ToastService);
  router: Router = inject(Router);
  renderer = inject(Renderer2);
  http = inject(HttpService);
  httpCancel = inject(HttpCancelService);
  authService: AuthService = inject(AuthService);
  errorService = inject(ErrorsService);

  constructor(private titleService: Title) {
    const title = $localize`${this.title}`;
    const prefix = this.state.store().tenant?.name || 'Mtek';
    this.titleService.setTitle($localize`${prefix} - ${title}`);
    dayjs.extend(advancedFormat);
    dayjs.extend(customParseFormat);
  }

  getThemeCssLink() {
    return 'https://staging.hillcroftinsurance.com/core/mtekcss';
  }

  async ngOnInit() {
    const currentHost = window.location.hostname;
    console.log(`Current Host is ${currentHost}: Loading the theme css...`);
    const linkElement = this.renderer.selectRootElement('#themecss', true);
    // update the href attribute
    this.renderer.setAttribute(
      linkElement,
      'href',
      `${this.getThemeCssLink()}?tenantHost=${currentHost}`,
    );
    console.log(document.getElementById('themecss')?.getAttribute('href'));
    // await this.actions.fetchThemes();
    this.router.events.subscribe((event) => {
      if (event.type === EventType.ActivationStart) {
        this.layout.startLoading();
      } else if (event.type === EventType.NavigationEnd) {
        this.layout.stopLoading();
        if (!this.authService.state.store().apiUser) {
          console.log($localize`Session expired, logging out.`);
          this.errorService.authError$.emit($localize`Session expired`);
        }
      } else if (event.type === EventType.NavigationError) {
        this.layout.stopLoading();
      } else {
        this.layout.stopLoading();
      }
    });
  }
}
