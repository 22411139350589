import { EventEmitter, inject, Injectable, Output } from '@angular/core';
import { State } from '../store';
import tw from '../../../tailwind.config';

@Injectable({
  providedIn: 'root',
})
export class LayoutService {
  @Output() setTitle$ = new EventEmitter<string>();
  state: State = inject(State);
  constructor() {
    // get the current locale from the full url
    const url = window.location.pathname;
    const locale = url.split('/')[1] || null;
    this.storeCurrentLocale(locale);
  }

  supportedLocales: Locale[] = [
    { code: 'en-US', label: 'English (US)', flag: 'us' },
    { code: 'sw-KE', label: 'Swahili (KE)', flag: 'ke' },
    { code: 'es-ES', label: 'Spanish (ES)', flag: 'es' },
    { code: 'fr-FR', label: 'French (FR)', flag: 'fr' },
    { code: 'pt-PT', label: 'Portuguese (PT)', flag: 'pt' },
  ];

  onMobileSidebarToggle(forceHide = false) {
    const sidebar = document.getElementById('sidebar');
    const sidebarBackdrop = document.getElementById('sidebarBackdrop');
    const toggleSidebarMobileHamburger = document.getElementById(
      'toggleSidebarMobileHamburger',
    );
    const toggleSidebarMobileClose = document.getElementById(
      'toggleSidebarMobileClose',
    );
    if (forceHide) {
      this.hideSidebarMobile(
        sidebar,
        sidebarBackdrop,
        toggleSidebarMobileHamburger,
        toggleSidebarMobileClose,
      );
      return;
    }
    this.toggleSidebarMobile(
      sidebar,
      sidebarBackdrop,
      toggleSidebarMobileHamburger,
      toggleSidebarMobileClose,
    );
  }

  private hideSidebarMobile(
    sidebar: HTMLElement | null,
    sidebarBackdrop: HTMLElement | null,
    toggleSidebarMobileHamburger: HTMLElement | null,
    toggleSidebarMobileClose: HTMLElement | null,
  ): void {
    sidebar?.classList.add('max-lg:hidden');
    sidebarBackdrop?.classList.add('max-lg:hidden');
    toggleSidebarMobileHamburger?.classList.remove('hidden');
    toggleSidebarMobileClose?.classList.add('hidden');
  }

  private toggleSidebarMobile(
    sidebar: HTMLElement | null,
    sidebarBackdrop: HTMLElement | null,
    toggleSidebarMobileHamburger: HTMLElement | null,
    toggleSidebarMobileClose: HTMLElement | null,
  ): void {
    sidebar?.classList.toggle('max-lg:hidden');
    sidebarBackdrop?.classList.toggle('max-lg:hidden');
    toggleSidebarMobileHamburger?.classList.toggle('hidden');
    toggleSidebarMobileClose?.classList.toggle('hidden');
  }

  startLoading() {
    this.state.store.update((state) => {
      return {
        ...state,
        loading: true,
      };
    });
  }

  stopLoading() {
    this.state.store.update((state) => {
      return {
        ...state,
        loading: false,
      };
    });
  }

  getCurrentLocale(): Locale | null {
    return this.state.store().locale;
  }
  private storeCurrentLocale(locale: string | null) {
    if (!locale) {
      // get the browser's locale
      const browserLocale = navigator.language;
      const systeLocale = Intl.DateTimeFormat().resolvedOptions().locale;
      console.log('System locale:', systeLocale);
      console.log('Browser locale:', browserLocale);
      const supportedLocales = this.supportedLocales.map((l) => l.code);
      // set to browser locale if supported, fall back to en-US
      if (browserLocale && supportedLocales.includes(browserLocale)) {
        locale = browserLocale;
      } else if (systeLocale && supportedLocales.includes(systeLocale)) {
        locale = systeLocale;
      }
       else {
        locale = 'en-US';
      }
    }
    this.state.store.update((state) => {
      state.locale =
        this.supportedLocales.find((l) => l.code === locale) ||
        this.supportedLocales[0];
      return state;
    });
  }
  getThemeColor(themeColor: TwThemeColor, level: TwColorLevel = 'DEFAULT') {
    const color = tw.theme.extend.colors[themeColor] as
      | string
      | Record<string, any>;
    let res = '';
    if (typeof color === 'string') {
      res = color;
    } else {
      res = color[level];
    }
    if (res.includes('var(--')) {
      // evaluate the variable
      const _var = this.sanitizeCssVarName(res) as string;
      const style = getComputedStyle(document.body);
      res = style.getPropertyValue(_var);
    }
    return res;
  }
  sanitizeCssVarName(cssVar: string) {
    // remove trailing )
    if (cssVar.includes(')')) {
      cssVar = cssVar.split(')')[0];
    }
    if (cssVar.includes('var(')) {
      cssVar = cssVar.split('var(')[1];
    }
    return cssVar;
  }
}

export type TwThemeColor =
  | 'primary'
  | 'secondary'
  | 'gray'
  | 'on-primary'
  | 'on-secondary'
  | 'danger'
  | 'success'
  | 'warning'
  | 'info'
  | 'royal'
  | 'on-success'
  | 'on-danger'
  | 'on-warning'
  | 'on-info';
export type TwColorLevel =
  | 'DEFAULT'
  | '50'
  | '100'
  | '200'
  | '300'
  | '400'
  | '500'
  | '600'
  | '700'
  | '800'
  | '900'
  | '950';
export interface Locale {
  code: string;
  label: string;
  flag: string;
}
