import { NgClass } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'mtek-button,[mtek-button],[mtekButton]',
  standalone: true,
  imports: [NgClass],
  templateUrl: './button.component.html',
  styleUrl: './button.component.css',
})
export class ButtonComponent {
  @Input({ required: false }) color:
    | 'primary'
    | 'secondary'
    | 'success'
    | 'danger'
    | 'warning'
    | 'info' = 'primary';
  @Input({ required: false }) outline = false;
  @Input({ required: false }) flat = false;
  @Input() overrideClasses = '';
  buttonClasses = {
    primary:
      'bg-primary drop-shadow text-on-primary hover:bg-gray-950 hover:text-gray-50 dark:text-white dark:bg-gray-800 dark:hover:bg-gray-900 dark:border dark:border-primary',
    secondary:
      'bg-secondary drop-shadow text-on-secondary hover:bg-gray-950 hover:text-gray-50 dark:text-white dark:bg-gray-800 dark:hover:bg-gray-900 dark:border dark:border-secondary',
    success:
      'bg-success drop-shadow text-on-success hover:bg-gray-950 hover:text-gray-50 dark:text-white dark:bg-gray-800 dark:hover:bg-gray-900 dark:border dark:border-success',
    danger:
      'bg-danger drop-shadow text-on-danger hover:bg-gray-950 hover:text-gray-50 dark:text-white dark:bg-gray-800 dark:hover:bg-gray-900 dark:border dark:border-danger',
    warning:
      'bg-warning drop-shadow text-on-warning hover:bg-gray-950 hover:text-gray-50 dark:text-white dark:bg-gray-800 dark:hover:bg-gray-900 dark:border dark:border-warning',
    info: 'bg-info drop-shadow text-on-info hover:bg-gray-950 hover:text-gray-50 dark:text-white dark:bg-gray-800 dark:hover:bg-gray-900 dark:border dark:border-info',
  };
  outlineClasses = {
    primary:
      'border border-primary text-primary hover:bg-primary hover:text-on-primary dark:border-primary dark:text-primary dark:hover:bg-primary dark:hover:text-gray-50',
    secondary:
      'border border-secondary text-secondary hover:bg-secondary hover:text-on-secondary dark:border-secondary dark:text-secondary dark:hover:bg-secondary dark:hover:text-gray-50',
    success:
      'border border-success text-success hover:bg-success hover:text-on-success dark:border-success dark:text-success dark:hover:bg-success dark:hover:text-gray-50',
    danger:
      'border border-danger text-danger hover:bg-danger hover:text-on-danger dark:border-danger dark:text-danger dark:hover:bg-danger dark:hover:text-gray-50',
    warning:
      'border border-warning text-warning hover:bg-warning hover:text-on-warning dark:border-warning dark:text-warning dark:hover:bg-warning dark:hover:text-gray-50',
    info: 'border border-info text-info hover:bg-info hover:text-on-info dark:border-info dark:text-info dark:hover:bg-info dark:hover:text-gray-50',
  };

  flatClasses = {
    primary:
      'text-primary hover:bg-primary hover:text-on-primary dark:text-primary dark:hover:bg-primary dark:hover:text-gray-50',
    secondary:
      'text-secondary hover:bg-secondary hover:text-on-secondary dark:text-secondary dark:hover:bg-secondary dark:hover:text-gray-50',
    success:
      'text-success hover:bg-success hover:text-on-success dark:text-success dark:hover:bg-success dark:hover:text-gray-50',
    danger:
      'text-danger hover:bg-danger hover:text-on-danger dark:text-danger dark:hover:bg-danger dark:hover:text-gray-50',
    warning:
      'text-warning hover:bg-warning hover:text-on-warning dark:text-warning dark:hover:bg-warning dark:hover:text-gray-50',
    info: 'text-info hover:bg-info hover:text-on-info dark:text-info dark:hover:bg-info dark:hover:text-gray-50',
  };

  get classes() {
    if (this.flat) {
      return this.mergeClasses(
        this.flatClasses[this.color],
        this.overrideClasses,
      );
    } else if (this.outline) {
      return this.mergeClasses(
        this.outlineClasses[this.color],
        this.overrideClasses,
      );
    }
    return this.mergeClasses(
      this.buttonClasses[this.color],
      this.overrideClasses,
    );
  }
  mergeClasses(c1: string, c2: string) {
    return [...c1.split(' '), ...c2.split(' ')].join(' ');
  }
}
