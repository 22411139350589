import defaultTheme from 'tailwindcss/defaultTheme';
import flowbite from 'flowbite/plugin';

export default {
  content: [
    './src/**/*.{html,ts}',
    './node_modules/flowbite/**/*.js',
    './node_modules/preline/preline.js',
  ],
  dark: 'selector',
  theme: {
    extend: {
      fontFamily: {
        poppins: ['"Poppins"', ...defaultTheme.fontFamily.sans],
        gilroy: ['"Gilroy-Regular"', ...defaultTheme.fontFamily.sans],
        'gilroy-bold': ['"Gilroy-Bold"', ...defaultTheme.fontFamily.sans],
        'gilroy-medium': ['"Gilroy-Medium"', ...defaultTheme.fontFamily.sans],
        'gilroy-light': ['"Gilroy-Light"', ...defaultTheme.fontFamily.sans],
      },
      colors: {
        primary: {
          50: 'var(--color-primary-50)',
          100: 'var(--color-primary-100)',
          200: 'var(--color-primary-200)',
          300: 'var(--color-primary-300)',
          400: 'var(--color-primary-400)',
          500: 'var(--color-primary-500)',
          600: 'var(--color-primary-600)',
          700: 'var(--color-primary-700)',
          800: 'var(--color-primary-800)',
          900: 'var(--color-primary-900)',
          950: 'var(--color-primary-950)',
          DEFAULT: 'var(--color-primary)',
        },
        'on-primary': {
          DEFAULT: 'var(--color-on-primary)',
        },
        secondary: {
          50: 'var(--color-secondary-50)',
          100: 'var(--color-secondary-100)',
          200: 'var(--color-secondary-200)',
          300: 'var(--color-secondary-300)',
          400: 'var(--color-secondary-400)',
          500: 'var(--color-secondary-500)',
          600: 'var(--color-secondary-600)',
          700: 'var(--color-secondary-700)',
          800: 'var(--color-secondary-800)',
          900: 'var(--color-secondary-900)',
          950: 'var(--color-secondary-950)',
          DEFAULT: 'var(--color-secondary)',
        },
        'on-secondary': {
          DEFAULT: 'var(--color-on-secondary)',
        },
        info: {
          50: 'var(--color-info-50)',
          100: 'var(--color-info-100)',
          200: 'var(--color-info-200)',
          300: 'var(--color-info-300)',
          400: 'var(--color-info-400)',
          500: 'var(--color-info-500)',
          600: 'var(--color-info-600)',
          700: 'var(--color-info-700)',
          800: 'var(--color-info-800)',
          900: 'var(--color-info-900)',
          950: 'var(--color-info-950)',
          DEFAULT: 'var(--color-info)',
        },
        'on-info': {
          DEFAULT: 'var(--color-on-info)',
        },

        warning: {
          50: 'var(--color-warning-50)',
          100: 'var(--color-warning-100)',
          200: 'var(--color-warning-200)',
          300: 'var(--color-warning-300)',
          400: 'var(--color-warning-400)',
          500: 'var(--color-warning-500)',
          600: 'var(--color-warning-600)',
          700: 'var(--color-warning-700)',
          800: 'var(--color-warning-800)',
          900: 'var(--color-warning-900)',
          950: 'var(--color-warning-950)',
          DEFAULT: 'var(--color-warning)',
        },
        'on-warning': {
          DEFAULT: 'var(--color-on-warning)',
        },

        success: {
          50: 'var(--color-success-50)',
          100: 'var(--color-success-100)',
          200: 'var(--color-success-200)',
          300: 'var(--color-success-300)',
          400: 'var(--color-success-400)',
          500: 'var(--color-success-500)',
          600: 'var(--color-success-600)',
          700: 'var(--color-success-700)',
          800: 'var(--color-success-800)',
          900: 'var(--color-success-900)',
          950: 'var(--color-success-950)',
          DEFAULT: 'var(--color-success)',
        },
        'on-success': {
          DEFAULT: 'var(--color-on-success)',
        },

        danger: {
          50: 'var(--color-danger-50)',
          100: 'var(--color-danger-100)',
          200: 'var(--color-danger-200)',
          300: 'var(--color-danger-300)',
          400: 'var(--color-danger-400)',
          500: 'var(--color-danger-500)',
          600: 'var(--color-danger-600)',
          700: 'var(--color-danger-700)',
          800: 'var(--color-danger-800)',
          900: 'var(--color-danger-900)',
          950: 'var(--color-danger-950)',
          DEFAULT: 'var(--color-danger)',
        },
        'on-danger': {
          DEFAULT: 'var(--color-on-danger)',
        },
        royal: {
          DEFAULT: 'var(--color-royal)',
          '50': 'var(--color-royal-50)',
          '100': 'var(--color-royal-100)',
          '200': 'var(--color-royal-200)',
          '300': 'var(--color-royal-300)',
          '400': 'var(--color-royal-400)',
          '500': 'var(--color-royal-500)',
          '600': 'var(--color-royal-600)',
          '700': 'var(--color-royal-700)',
          '800': 'var(--color-royal-800)',
          '900': 'var(--color-royal-900)',
          '950': 'var(--color-royal-950)',
        },
        'on-royal': {
          DEFAULT: 'var(--color-on-royal)',
        },
        gray: {
          DEFAULT: 'var(--color-gray)',
          '50': 'var(--color-gray-50)',
          '100': 'var(--color-gray-100)',
          '200': 'var(--color-gray-200)',
          '300': 'var(--color-gray-300)',
          '400': 'var(--color-gray-400)',
          '500': 'var(--color-gray-500)',
          '600': 'var(--color-gray-600)',
          '700': 'var(--color-gray-700)',
          '800': 'var(--color-gray-800)',
          '900': 'var(--color-gray-900)',
          '950': 'var(--color-gray-950)',
        },
      },
    },
  },
  plugins: [flowbite],
};
