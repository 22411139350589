<mtek-basic-card
  themeColor="white"
  overrideClasses="!bg-danger-50 !text-danger drop-shadow"
>
  <div class="w-full min-w-5xl">
    <h2 heading class="font-gilroy-bold text-danger dark:text-danger-50">
      {{ title() }}
    </h2>
    <p class="text-sm text-gray-800 dark:text-gray-400">{{ body() }}</p>

    <div class="flex flex-row-reverse justify-between gap-3 my-3">
      <button mtekButton type="button" [outline]="true" (click)="confirm()">
        {{ confirmTitle() }}
      </button>
      <button
        mtekButton
        type="button"
        [outline]="true"
        color="danger"
        (click)="cancel()"
      >
        {{ cancelTitle() }}
      </button>
    </div>
  </div>
</mtek-basic-card>
