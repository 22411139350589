import { inject, Injectable } from '@angular/core';
import { State } from './store';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import * as packageJson from '../../package.json';
import { User } from './pages/users/users.service';
import { ApiResponse, HttpService } from './services/http.service';
import { apiURL, Endpoints } from './configs/http.config';
import { Product } from './services/products.service';
import {
  forkJoin,
  map,
  noop,
  Observable,
  of,
  shareReplay,
  throwError,
} from 'rxjs';
import { Underwriter } from './services/underwriters.service';
import { ThirdParty } from './services/third-parties.service';
import { ToastService } from './services/toast.service';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { LayoutService } from './services/layout.service';
import { ThemeService } from './services/theme.service';
import { VehicleModel } from './pages/policies/add-policy/policy-details-forms.service';
import { Router } from '@angular/router';
import { v4 } from 'uuid';
import { AuthService } from './services/auth.service';
import { environment } from '../environments/environment';
import { INatureOfClaim } from './pages/claims/claims.interfaces';
import { Policy } from './pages/policies/policies.service';
import { ICommissionStatement } from './pages/policies/policies.interface';
import { IPayment } from './pages/payments/payments.interfaces';
import { DynamicHostDirective } from './directives/dynamic-host.directive';

const CACHE_SIZE = 3;
@Injectable({
  providedIn: 'root',
})
export class AppService {
  state = inject(State);
  http = inject(HttpService);
  toasts = inject(ToastService);
  auth = inject(AuthService);
  router = inject(Router);
  layouts = inject(LayoutService);
  themes = inject(ThemeService);
  private products$: Observable<Product[]> | Observable<any[]> | undefined;
  private quotePackages$:
    | Observable<{ option: any; optionName: any }[]>
    | undefined;
  private underwriters$: Observable<Underwriter[]> | undefined;
  private thirdParties$: Observable<ThirdParty[]> | undefined;
  private combinedUnderwritersThirdPartiesCache$:
    | Observable<Underwriter[]>
    | undefined;
  private vehicleMakes$: Observable<any[]> | undefined;
  private rbacRolesCache$: Observable<any[]> | undefined;
  private collectionsCache$: Observable<any[]> | undefined;
  private sasToken$?: Observable<string>;
  toggleDropdown(dropdownId: string) {
    const btn = document.querySelector(
      `[data-dropdown-toggle="${dropdownId}"]`,
    ) as HTMLButtonElement;
    if (btn) btn.click();
  }

  confirm(message: string, title: string, onConfirm: () => void) {
    this.toasts.confirm(message, title, onConfirm);
  }

  getKeys(obj: Record<string, any>): string[] {
    return Object.keys(obj);
  }

  getBearerToken(): string | null {
    return this.getStore().auth.idToken;
  }

  loadDynamicComponent(
    dynamicHost: DynamicHostDirective,
    component: any,
    componentInputs: Record<string, any> = {},
    onDone: (res?: any) => void = noop,
  ) {
    const viewContainerRef = dynamicHost.viewContainerRef;
    viewContainerRef.clear();
    const comp: any = viewContainerRef.createComponent(component);
    Object.keys(componentInputs).forEach((key) => {
      comp.setInput(key, componentInputs[key]);
    });
    comp.instance.done?.subscribe(() => {
      onDone();
    });
  }

  camelToTitle(str: string): string {
    return str
      .replace(/[-_]/g, ' ') // Replace hyphens and underscores with spaces
      .replace(/([a-z0-9])([A-Z])/g, '$1 $2') // Add space before capital letters
      .replace(/\b\w/g, (s) => s.toUpperCase()); // Capitalize the first letter of each word
  }
  paginate(
    page: number,
    perPage = 10,
    useStandardMongodbFormat = false,
    sort?: { field: string; direction: 'asc' | 'desc' },
  ) {
    if (perPage <= 0) return {};
    let pagination: IPagination = {};
    page = Math.max(page, 1);
    pagination = {
      ...pagination,
      skip: perPage * (page - 1),
    };

    pagination = {
      ...pagination,
      limit: perPage,
    };
    if (sort) {
      pagination = {
        ...pagination,
        sort: {
          [sort.field]: sort.direction === 'asc' ? 1 : -1,
        },
      };
    }
    if (useStandardMongodbFormat) {
      const res: IPagination = {
        $skip: pagination.skip,
        $limit: pagination.limit,
      };
      if (sort) {
        res['$sort'] = pagination.sort;
      }
      return res;
    }
    return pagination;
  }

  async fetchNatureOfClaims(): Promise<INatureOfClaim[]> {
    return new Promise((resolve, reject) => {
      this.http
        .get(
          apiURL(
            Endpoints.domestic.listGadgetNatureOfClaims,
            {},
            Endpoints.domestic.baseUrl,
          ),
        )
        .then((res) => {
          resolve(res.Payload);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getPaymentsLogByQuoteId(quoteId: string): Observable<IPayment[]> {
    return this.http
      .makePostRequest(
        apiURL(
          Endpoints.payments.listPaymentLogs,
          {},
          Endpoints.payments.baseUrl,
        ),
        {
          received_underscore_id: quoteId,
          $or: [{ status: 'SUCCESS' }, { paid: true }],
          fields: [
            '_id',
            'service',
            'product',
            'amount',
            'insurer',
            'synced',
            'paid',
            'isFinanced',
            '_timestamp',
            'bimaRedemption',
            'details',
            'transactionType',
            'mtekPaymentRef',
            'mpesaPaybillNo',
            'isSettledToUnderwriter',
            '_utimestamp',
            'paidAmount',
            'broker',
          ],
        },
      )
      .pipe(
        map((res) => {
          return res.Payload;
        }),
      );
  }
  objectKeys(obj: object) {
    return Object.keys(obj);
  }
  objectValues(obj: object) {
    return Object.values(obj);
  }
  objectEntries(obj: object) {
    return Object.entries(obj);
  }
  objectHasOwn(obj: object, key: string) {
    return Object.hasOwn(obj, key);
  }

  getPolicySpecificFields(product: string, aPolicy: Policy | any) {
    let fields = {};
    switch (product) {
      case AppEnums.PRODUCT_MOTOR:
        fields = {
          policyType: aPolicy?.policy?.policyType,
          use: aPolicy?.policy?.use,
          period: `${aPolicy?.policy?.duration} ${aPolicy?.policy?.durationFlagName}`,
          vehicle: aPolicy?.policyVehicle?.carType
            ? aPolicy?.policyVehicle?.carType
            : aPolicy?.motor?.carType,
          make: aPolicy?.policyVehicle?.make
            ? aPolicy?.policyVehicle?.make
            : aPolicy?.motor?.make,
          model: aPolicy?.policyVehicle?.model
            ? aPolicy?.policyVehicle?.model
            : aPolicy?.motor?.model,
          body: aPolicy?.policyVehicle?.bodyType
            ? aPolicy?.policyVehicle?.bodyType
            : aPolicy?.motor?.body,
          year: aPolicy?.policyVehicle?.yom
            ? aPolicy?.policyVehicle?.yom
            : aPolicy?.motor?.yom,
          value: aPolicy?.policyVehicle?.value
            ? aPolicy?.policyVehicle?.value
            : aPolicy?.motor?.value,
          registrationNumber: aPolicy?.policyVehicle?.registrationNumber,
          chassisNumber: aPolicy?.policyVehicle?.chassisNumber,
          insurerPolicyNumber: aPolicy?.issuedPolicyNumber
            ? aPolicy?.issuedPolicyNumber
            : aPolicy?.certificate?.policyNumber,
          certificateNumber: aPolicy?.certificate?.certificateNumber,
          policyStartDate: aPolicy?.certificate?.validFrom
            ? aPolicy?.certificate?.validFrom
            : aPolicy?.policyStartDate,
          policyEndDate: aPolicy?.certificate?.validTo
            ? aPolicy?.certificate?.validTo
            : aPolicy?.policyEndDate,
        };

        break;
      case AppEnums.PRODUCT_HEALTH:
        fields = {
          coverLimit: aPolicy?.inpatient?.coverLimit,
          Entity: aPolicy?.inpatient?.entity,
          coverPlan: aPolicy?.inpatient?.bouquetName,
        };
        break;
      case AppEnums.PRODUCT_PA:
        fields = {
          policyType: aPolicy?.policy?.coverType,
          policyTerm: aPolicy?.policy?.term,
          occupation: aPolicy?.policy?.occupation,
        };
        if (
          aPolicy?.insurer == AppEnums.PartnerIdBritam &&
          aPolicy?.ModuleID == AppEnums.PartnerBuuPass
        ) {
          fields = {
            ...fields,
            ...{
              source: aPolicy?.policyHolder?.source,
              destination: aPolicy?.policyHolder?.destination,
            },
          };
        }
        break;
      case AppEnums.PRODUCT_TRAVEL:
        fields = {
          coverLimit: aPolicy?.coverLimit,
          travelReason: aPolicy?.policy?.reason,
          policyPeriodDays: aPolicy?.policy?.coverDays,
          departureCountry: aPolicy?.policy?.departure?.country,
        };
        break;
      case AppEnums.PRODUCT_DOMESTIC:
        fields = {
          coverLimit: aPolicy?.coverLimit,
        };
        break;
      case AppEnums.PRODUCT_GADGET:
        fields = {
          coverLimit: aPolicy?.coverLimit,
          gadgetMake: aPolicy?.policy?.gadget?.make,
          gadgetModel: aPolicy?.policy?.gadget?.model,
          policyTerm: aPolicy?.policy?.term,
        };
        break;
      case AppEnums.PRODUCT_EVACUATION:
        break;
      case AppEnums.PRODUCT_LIFE:
        break;
      default:
        break;
    }
    return fields;
  }
  get paymentChannels(): string[] {
    return [
      'M-PESA',
      'Bank Deposit',
      'Bank Transfer',
      'Cash',
      'Card',
      'Cheque',
      'Other',
      'Wallet',
    ];
  }
  get getSegment1Options(): IKeyValueCategory[] {
    return [
      { key: 'productPartner', value: 'Product Partner', categories: [] },
      { key: 'salesPartner', value: 'Sales Partner', categories: [] },
    ];
  }

  get getSegment2Options(): IKeyValueCategory[] {
    return [
      { key: 'regulated', value: 'Regulated Partner', categories: [] },
      { key: 'unregulated', value: 'Unregulated Partner', categories: [] },
    ];
  }

  get getSegment3Options(): IKeyValueCategory[] {
    return [
      {
        key: 'affinity',
        value: 'Affinity Partner',
        categories: [],
      },
      { key: 'saas', value: 'SaaS Partner', categories: [] },
      {
        key: 'associate',
        value: 'Associate Partner',
        categories: [
          { key: 'agent', value: 'Insurance Agent', categories: [] },
          { key: 'broker', value: 'Insurance Broker', categories: [] },
        ],
      },
      {
        key: 'collective',
        value: 'Collective Partner',
        categories: [
          { key: 'patapesa', value: 'Pata Pesa', categories: [] },
          { key: 'primepartner', value: 'Prime Partner', categories: [] },
          {
            key: 'primepartnerplus',
            value: 'Prime Partner Plus',
            categories: [],
          },
        ],
      },
    ];
  }
  getStore() {
    return this.state.store();
  }

  getFormErrors(form: AbstractControl | null) {
    if (!form) return {};
    if (form instanceof FormControl) {
      // Return FormControl errors or null
      return form.errors ?? null;
    }
    if (form instanceof FormGroup) {
      const groupErrors: any = form.errors;
      // Form group can contain errors itself, in that case add'em
      const formErrors: any = groupErrors ? { groupErrors } : {};
      Object.keys(form.controls).forEach((key: any) => {
        // Recursive call of the FormGroup fields
        const error = this.getFormErrors(form.get(key));
        if (error !== null) {
          // Only add error if not null
          formErrors[key] = error;
        }
      });
      // Return FormGroup errors or null
      return Object.keys(formErrors).length > 0 ? formErrors : null;
    }
  }
  slugify(str: string): string {
    str = str.replace(/^\s+|\s+$/g, ''); // trim leading/trailing white space
    str = str.toLowerCase(); // convert string to lowercase
    str = str
      .replace(/[^a-z0-9 -]/g, '') // remove any non-alphanumeric characters
      .replace(/\s+/g, '-') // replace spaces with hyphens
      .replace(/-+/g, '-'); // remove consecutive hyphens
    return str;
  }

  getAppVersion(): string {
    // parse version from package.json
    return packageJson?.version || '';
  }

  getSessionUser(): User {
    return this.state.store().apiUser as User;
  }

  async resolveSasToken(): Promise<string> {
    return new Promise((resolve, reject) => {
      this.sasToken$?.subscribe({
        next(value) {
          resolve(value);
        },
        error(err) {
          reject(err);
        },
      });
    });
  }
  applySasToken(url: string, token: string) {
    // remove everything after ? and replace with token
    url = url.split('?')[0];
    // Download the image via ajax, then display it in an iframe
    return `${url}${token}`;
  }
  async viewAttachment(
    attachmentUrl?: string,
    token?: string,
    openWindow = true,
  ): Promise<string | void> {
    if (!attachmentUrl?.length) {
      this.toasts.error($localize`No attachment URL provided`);
      return;
    }
    if (!token) {
      this.toasts.error($localize`No token provided`);
      return;
    }
    // remove everything after ? and replace with token
    attachmentUrl = attachmentUrl.split('?')[0];
    // Download the image via ajax, then display it in an iframe
    const url = `${attachmentUrl}${token}`;

    if (openWindow) {
      this.layouts.startLoading();

      fetch(url).then((res) => {
        res.blob().then((blob) => {
          const url = URL.createObjectURL(blob);
          this.layouts.stopLoading();
          const win = window.open(url, '_blank');
          if (win) {
            win.focus();
          }
        });
      });
    } else {
      return new Promise((resolve) => {
        fetch(url)
          .then((res) => {
            res.blob().then((blob) => {
              const url = URL.createObjectURL(blob);
              resolve(url);
            });
          })
          .catch((err) => {
            console.error(err);
            resolve('');
          });
      });
    }
  }

  async getVehicleMakes(): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this.http
        .get(
          apiURL(Endpoints.motor.getVehicleMakes, {}, Endpoints.motor.baseUrl),
        )
        .then((res: ApiResponse) => {
          if (res?.Status !== 200) {
            reject(res.Payload);
          }
          resolve(res.Payload);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  async getVehicleModels(makeId: { $oid: string }): Promise<any[]> {
    console.log(`Getting models for make: ${makeId}`);
    return new Promise((resolve, reject) => {
      this.http
        .post(
          apiURL(Endpoints.motor.getVehicleModels, {}, Endpoints.motor.baseUrl),
          {
            _id: makeId,
          },
        )
        .then((res: ApiResponse) => {
          if (res?.Status !== 200) {
            reject(res.Payload);
          }
          if (!res.Payload) {
            resolve([]);
          }
          if (!Object.hasOwn(res.Payload, 'models')) {
            resolve([]);
          }
          resolve(res.Payload.models);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  get productsCache(): Observable<Product[]> {
    if (!this.products$) {
      this.products$ = this.http
        .makePostRequest(apiURL(Endpoints.listProducts), {}, {}, true)
        .pipe(
          map((res) => {
            if (res.Payload && res.Status === 200) {
              const products = res.Payload as Product[];
              return products.sort(
                (a, b) => a.policyName?.localeCompare(b.policyName || '') || 0,
              );
            }
            return [];
          }),
        )
        .pipe(shareReplay(CACHE_SIZE)) as Observable<Product[]>;
    }
    return this.products$;
  }

  get quotePackagesCache(): Observable<{ option: any; optionName: any }[]> {
    if (!this.quotePackages$) {
      const cdn =
        'https://mtekcdnstoragev2.blob.core.windows.net/admin/quote_packages.json';
      this.quotePackages$ = this.http.api
        .get(cdn)
        .pipe(
          map((res) => {
            const records = res as any[];
            return records.map((p) => ({
              option: p.quotePackage,
              optionName: p.quotePackageName,
            }));
          }),
        )
        .pipe(shareReplay(CACHE_SIZE));
    }
    return this.quotePackages$;
  }
  get underwritersCache(): Observable<Underwriter[]> {
    if (!this.underwriters$) {
      this.underwriters$ = this.http
        .makePostRequest(apiURL(Endpoints.listUnderwriters), {}, {}, true)
        .pipe(
          map((res) => {
            if (res.Status !== 200) {
              throwError(() => new Error(res.Payload));
              return [];
            }
            if (res.Payload && res.Status === 200) {
              const records = res.Payload as Underwriter[];
              return records.sort(
                (a, b) => a.name?.localeCompare(b.name || '') || 0,
              );
            }
            return [];
          }),
        )
        .pipe(shareReplay(CACHE_SIZE)) as Observable<Underwriter[]>;
    }
    return this.underwriters$;
  }
  get thirdPartiesCache(): Observable<ThirdParty[]> {
    if (!this.thirdParties$) {
      console.log('Fetching third parties from server');
      this.thirdParties$ = this.http
        .makePostRequest(apiURL(Endpoints.listThirdParties), {}, {}, true)
        .pipe(
          map((res) => {
            if (res.Payload && res.Status === 200) {
              const records = res.Payload as ThirdParty[];
              return records;
            }
            return [];
          }),
        )
        .pipe(shareReplay(CACHE_SIZE)) as Observable<ThirdParty[]>;
    } else {
      console.log('Fetching third parties from cache');
    }

    return this.thirdParties$;
  }

  get getThirdPartiesFromDB(): Observable<ThirdParty[]> {
    if (!this.thirdParties$) {
      this.thirdParties$ = this.http
        .makePostRequest(`${environment.CORE_API_URL}/listThirdParties`, {
          fields: [
            '_id',
            'thirdPartyId',
            'name',
            'products',
            'segments',
            'referralCode',
          ],
        })
        .pipe(
          map((res) => {
            if (res.Status == 200) {
              const t: ThirdParty[] = res.Payload;
              return t.sort((a, b) => {
                if (a?.name) {
                  return a.name.localeCompare(b.name || '');
                }
                return 0;
              });
            }
            return [];
          }),
        )
        .pipe(shareReplay(CACHE_SIZE));
    }
    return this.thirdParties$;
  }

  get getCombinedUnderwriterThirdpartyFromCdn(): Observable<Underwriter[]> {
    if (!this.combinedUnderwritersThirdPartiesCache$) {
      this.combinedUnderwritersThirdPartiesCache$ = forkJoin({
        underwriters: this.underwritersCache,
        thirdParties: this.thirdPartiesCache,
      })
        .pipe(
          map((res: any) => {
            const combined: (Underwriter | any)[] = res.underwriters;
            const tps: ThirdParty[] = res.thirdParties;
            tps.forEach((tp) => {
              combined.push({
                logoUrl: tp.logoUrl,
                name: tp.name,
                insurer: tp._id,
                _id: tp._id,
                thirdPartyId: tp._id,
                products: tp.products,
              });
            });
            return [...new Set(combined.map((tp) => tp))].sort((a, b) =>
              a?.name?.localeCompare(b.name),
            );
          }),
        )
        .pipe(shareReplay(CACHE_SIZE));
    }
    return this.combinedUnderwritersThirdPartiesCache$;
  }

  get rbacRolesCache() {
    if (!this.rbacRolesCache$) {
      this.rbacRolesCache$ = this.http
        .makePostRequest(apiURL(Endpoints.listRoles), {}, {}, true)
        .pipe(
          map((res) => {
            if (res.Status == 200) {
              return res.Payload;
            } else {
              return;
            }
          }),
        )
        .pipe(shareReplay(CACHE_SIZE));
    }
    return this.rbacRolesCache$;
  }
  get currentTenant(): ThirdParty | null {
    return this.getStore().tenant;
  }

  async fetchSasToken(): Promise<string> {
    return new Promise((resolve) => {
      this.http
        .get(apiURL('getSASkey'))
        .then((res) => {
          resolve(res.Payload || '');
        })
        .catch((err) => {
          console.error(err);
          resolve('');
        });
    });
  }
  get getSasToken(): Observable<string> {
    if (!this.sasToken$) {
      this.sasToken$ = this.http
        .makeGetRequest(apiURL('getSASkey'))
        .pipe(
          map((res) => {
            if (res.Status == 200) {
              return res.Payload;
            }
            return '';
          }),
        )
        .pipe(shareReplay(CACHE_SIZE));
    }
    return this.sasToken$;
  }

  get carTypes() {
    return [];
  }
  getVehicleModelForMake(make: string): Observable<any[]> {
    return this.http.api
      .get(
        apiURL(
          Endpoints.vehiclesCdn.getModelsForMake,
          { make },
          Endpoints.vehiclesCdn.baseUrl,
        ),
        { observe: 'body', params: { format: 'json' } },
      )
      .pipe(
        map((res: any) => {
          if (Object.hasOwn(res, 'Results')) {
            return res.Results;
          } else {
            return [];
          }
        }),
      );
  }
  getVehicleModelByMakeId(makeId: string): Observable<VehicleModel[]> {
    return this.http.api
      .get(
        apiURL(
          Endpoints.vehiclesCdn.getModelsByMakeId,
          { makeId },
          Endpoints.vehiclesCdn.baseUrl,
        ),
        { observe: 'body', params: { format: 'json' } },
      )
      .pipe(
        map((res: any) => {
          if (Object.hasOwn(res, 'Results')) {
            return res.Results;
          } else {
            return [];
          }
        }),
      );
  }

  getRolesByUser(rolesArray: any[]): string[] {
    let roles = [];
    if (rolesArray) {
      roles = rolesArray.reduce((acc, obj) => {
        const key = Object.keys(obj)[0];
        if (obj[key]) {
          acc.push(key);
        }
        return acc;
      }, []);
    }

    roles = [...new Set(roles)] as string[];
    return roles;
  }
  getRoles(rolesArr?: string[]): string[] {
    if (this.getSessionUser() === null || this.getSessionUser() == undefined) {
      return [];
    }
    let rolesArray = [];
    if (rolesArr) {
      rolesArray = rolesArr;
    } else {
      rolesArray = this.getSessionUser().roles;
    }
    const roles = rolesArray.reduce((acc: any, obj: any) => {
      const key = Object.keys(obj)[0];
      if (obj[key]) {
        acc.push(key);
      }
      return acc;
    }, []);
    return [...new Set(roles)] as string[];
  }

  isTrustGro() {
    return this.getSessionUser()?.thirdPartyId === AppEnums.PartnerTrustgro;
  }

  isKyosk() {
    return this.getSessionUser()?.thirdPartyId === AppEnums.PartnerKyosk;
  }

  isAdminOrTrustGro() {
    return this.isAdmin() || this.isTrustGro();
  }

  isThirdParty() {
    return this.getRoles().includes(AppEnums.RoleLeadGenerator);
  }

  isAdminOrThirdParty() {
    return [AppEnums.RoleAdmin, AppEnums.RoleLeadGenerator].some((role) =>
      this.getRoles().includes(role),
    );
  }
  isAdminOrThirdPartyOrPrimePartner() {
    return [
      AppEnums.RoleAdmin,
      AppEnums.RoleLeadGenerator,
      AppEnums.RolePrimePartner,
    ].some((role) => this.getRoles().includes(role));
  }
  isAdmin() {
    return this.getRoles().includes(AppEnums.RoleAdmin);
  }

  isPrimePartner() {
    return this.getRoles().includes(AppEnums.RolePrimePartner);
  }

  isPartner() {
    return this.getRoles().includes(AppEnums.RolePartner);
  }

  isAdminOrPartner() {
    return [AppEnums.RoleAdmin, AppEnums.RolePartner].some((role) =>
      this.getRoles().includes(role),
    );
  }

  isFinance() {
    return this.getRoles().includes(AppEnums.RoleFinance);
  }

  isAdminOrFinance() {
    return [AppEnums.RoleAdmin, AppEnums.RoleFinance].some((role) =>
      this.getRoles().includes(role),
    );
  }

  isAstrabelMember() {
    return this.getSessionUser()?.thirdPartyId === AppEnums.PartnerAstrabel;
  }

  isEmbassavaMember() {
    return this.getSessionUser()?.thirdPartyId === AppEnums.PartnerEmbassava;
  }

  isLuminousMember() {
    return this.getSessionUser()?.thirdPartyId === AppEnums.PartnerLuminous;
  }

  generateUniqueId(name: string) {
    const uuid = v4();
    // cleanup the name:
    // 1. remove all non-alphanumeric characters
    // 2. replace spaces with hyphens
    // 3. remove consecutive hyphens

    const cleanedName = name
      // replace underscores and spaces with hyphens
      .toLocaleLowerCase()
      .replace(/[_\s]/g, '-')
      .replace(/[^a-z0-9 -]/g, '')
      .replace(/\s+/g, '-')
      .replace(/-+/g, '-');

    // pick only the first 8 characters of the name
    // and concatenate with the uuid

    const uid = `${cleanedName.substring(0, 10)}-${uuid}`;
    // once again, replace consecutive hyphens with a single hyphen
    return uid.replace(/-+/g, '-');
  }

  hasPermission(perm: string): boolean {
    return this.auth.hasPermission(perm);
  }

  isValidEmail(email: string) {
    if (
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        email,
      )
    ) {
      return true;
    } else {
      return false;
    }
  }

  isValidPhoneNumber(phoneNumber: string) {
    const phoneNumberPattern = /^\+\d{12}$/;
    if (phoneNumber.match(phoneNumberPattern)) {
      return true;
    } else {
      return false;
    }
  }
  makeSearchQuery(query: string, searchFields: string[] = []) {
    return searchFields.map((field) => ({
      [field]: {
        $regex: query,
        $options: 'i',
      },
    }));
  }
  processHttpErrors(
    e: HttpErrorResponse | HttpResponse<ApiResponse | any> | any,
    toast = false,
  ) {
    let msg = $localize`Server Error`;
    if (e.Payload && e.Status) {
      msg = e.Payload || $localize`${e.Status} Server Error`;
    }
    if (e instanceof HttpErrorResponse) {
      if (e.error && e.error.Payload) {
        msg = e.error.Payload;
      } else if (e.error && e.error.Status) {
        msg = $localize`A server error occurred: ${e.error.Status}`;
      } else {
        msg = e.message;
      }
    } else {
      msg = e.message;
    }
    if (toast) {
      this.toasts.warning(msg);
    }
    return msg;
  }
  get collectionsCache(): Observable<any[]> {
    if (!this.collectionsCache$) {
      this.collectionsCache$ = this.http
        .makeGetRequest(apiURL(Endpoints.collections), {}, {}, true)
        .pipe(
          map((res) => {
            if (res.Status == 200) {
              return res.Payload;
            } else {
              return;
            }
          }),
        )
        .pipe(shareReplay(CACHE_SIZE));
    }
    return this.collectionsCache$;
  }

  async setupTestContext() {
    // if no local object, login first:
    const existing = localStorage.getItem('testContext');
    const parsed = JSON.parse(existing || '{}');
    if (!parsed?.auth?.idToken) {
      // login first
      console.log('Tests: Logging in for the first time.');
      await this.auth.manualLogin(
        environment.testCredentials.username,
        environment.testCredentials.password,
      );

      // now grab all the storage and set it to context
      const context = this.state.store();

      localStorage.setItem('testContext', JSON.stringify(context));
    } else {
      console.log('Tests: Using existing session.');
    }

    // possibly restore existing session to the store
    this.restoreTestContext();
    // give it some time before returning;
    setTimeout(() => noop(), 2000);
  }

  restoreTestContext() {
    const context = localStorage.getItem('testContext');
    if (!context) return;
    const parsed = JSON.parse(context);
    // possibly the session is already set
    if (this.state.store().auth.idToken === parsed.auth.idToken) return;
    this.state.store.update((state) => {
      state = { ...state, ...parsed };
      return state;
    });
    return;
  }
  clearTestContext() {
    localStorage.removeItem('testContext');
  }

  getMotorQuotesAndPolicies(data: any): Observable<ApiResponse> {
    return this.http.makePostRequest(
      apiURL(`/adminaggregate`, {}, environment.CORE_API_URL),
      data,
    );
  }

  getMedicalQuotesAndPolicies(data: any): Observable<ApiResponse> {
    return this.http.makePostRequest(
      apiURL('/adminlistquote', {}, `${environment.CORE_API_URL}`),
      data,
    );
  }

  getMedicalAggregateQuotesAndPolicies(data: any): Observable<ApiResponse> {
    return this.http.makePostRequest(
      apiURL('/adminaggregate', {}, `${environment.CORE_API_URL}`),
      data,
    );
  }

  getTelemedicineAggregateQuotesAndPolicies(
    data: any,
  ): Observable<ApiResponse> {
    return this.http.makePostRequest(
      apiURL('/adminaggregate', {}, `${environment.CORE_API_URL}`),
      data,
    );
  }
  getTravelQuotesAndPolicies(data: any): Observable<ApiResponse> {
    return this.http.makePostRequest(
      apiURL('/adminlisttravelquote', {}, `${environment.TRAVEL_API_URL}`),
      data,
    );
  }

  getTravelAggregateQuotesAndPolicies(data: any): Observable<ApiResponse> {
    return this.http.makePostRequest(
      apiURL('/adminaggregate', {}, `${environment.CORE_API_URL}`),
      data,
    );
  }

  getLastExpenseAggregateQuotesAndPolicies(data: any): Observable<ApiResponse> {
    return this.http.makePostRequest(
      apiURL('/adminaggregate', {}, `${environment.CORE_API_URL}`),
      data,
    );
  }

  getUnisureAggregateQuotesAndPolicies(data: any): Observable<ApiResponse> {
    return this.http.makePostRequest(
      apiURL('/adminlistquote', {}, environment.CORE_API_URL),
      data,
    );
  }

  getOtherPoliciesAggregateQuotesAndPolicies(
    data: any,
  ): Observable<ApiResponse> {
    return this.http.makePostRequest(
      apiURL('/adminlistquote', {}, environment.CORE_API_URL),
      data,
    );
  }

  getOtherPoliciesAggregate(data: any): Observable<ApiResponse> {
    return this.http.makePostRequest(
      apiURL('/adminaggregate', {}, environment.CORE_API_URL),
      data,
    );
  }
  getOtherQuestions(questions_id: string): Observable<ApiResponse> {
    return this.http.makePostRequest(
      apiURL('/getquestions', {}, `${environment.CORE_API_URL}`),
      {
        _id: questions_id,
      },
    );
  }

  getDomesticQuotesAndPolicies(data: any): Observable<ApiResponse> {
    return this.http.makePostRequest(
      apiURL('/adminlistquote', {}, environment.CORE_API_URL),
      data,
    );
  }

  getDomesticAggregateQuotesAndPolicies(data: any): Observable<ApiResponse> {
    return this.http.makePostRequest(
      apiURL('/adminaggregate', {}, environment.CORE_API_URL),
      data,
    );
  }
  getDomesticCommissionStatementReport(
    data: any,
  ): Observable<ICommissionStatement[]> {
    return this.http
      .makePostRequest(
        apiURL(
          '/getcommissionstatementreport',
          {},
          environment.DOMESTIC_API_URL,
        ),
        data,
      )
      .pipe(
        map((res) => {
          return res.Payload?.quotes;
        }),
      );
  }

  getGadgetQuotesAndPolicies(data: any): Observable<ApiResponse> {
    return this.http.makePostRequest(
      apiURL('/adminlistgadgetquote', {}, environment.CORE_API_URL),
      data,
    );
  }

  getGadgetAggregateQuotesAndPolicies(data: any): Observable<ApiResponse> {
    return this.http.makePostRequest(
      apiURL('/adminaggregate', {}, environment.CORE_API_URL),
      data,
    );
  }

  getLifeCommissionStatementReport(
    data: any,
  ): Observable<ICommissionStatement[]> {
    return this.http
      .makePostRequest(
        apiURL('getcommissionstatementreport', {}, environment.LIFE_API_URL),
        data,
      )
      .pipe(
        map((res) => {
          return res.Payload?.quotes;
        }),
      );
  }
  getGolfCommissionStatementReport(
    data: any,
  ): Observable<ICommissionStatement[]> {
    return this.http
      .makePostRequest(
        apiURL('getcommissionstatementreport', {}, environment.GOLF_API_URL),
        data,
      )
      .pipe(
        map((res) => {
          return res.Payload?.quotes;
        }),
      );
  }
  getPaCommissionStatementReport(
    data: any,
  ): Observable<ICommissionStatement[]> {
    return this.http
      .makePostRequest(
        apiURL('getcommissionstatementreport', {}, environment.PA_API_URL),
        data,
      )
      .pipe(
        map((res) => {
          return res.Payload?.quotes;
        }),
      );
  }
  getEvacuationCommissionStatementReport(
    data: any,
  ): Observable<ICommissionStatement[]> {
    return this.http
      .makePostRequest(
        apiURL(
          'getcommissionstatementreport',
          {},
          environment.EVACUATION_API_URL,
        ),
        data,
      )
      .pipe(
        map((res) => {
          return res.Payload?.quotes;
        }),
      );
  }
  getMotorCommissionStatementReport(
    data: any,
  ): Observable<ICommissionStatement[]> {
    return this.http
      .makePostRequest(
        apiURL(`getcommissionstatementreport`, {}, environment.MOTOR_API_URL),
        data,
      )
      .pipe(
        map((res) => {
          return res.Payload?.quotes;
        }),
      );
  }
  getMedicalCommissionStatementReport(
    data: any,
  ): Observable<ICommissionStatement[]> {
    return this.http
      .makePostRequest(
        apiURL(`getcommissionstatementreport`, {}, environment.MEDICAL_API_URL),
        data,
      )
      .pipe(
        map((res) => {
          return res.Payload?.quotes;
        }),
      );
  }
  getTravelCommissionStatementReport(
    data: any,
  ): Observable<ICommissionStatement[]> {
    return this.http
      .makePostRequest(
        `${environment.TRAVEL_API_URL}/getcommissionstatementreport`,
        data,
      )
      .pipe(
        map((res) => {
          return res.Payload?.quotes;
        }),
      );
  }

  getPersonalAccidentQuotesAndPolicies(data: any): Observable<ApiResponse> {
    return this.http.makePostRequest(
      apiURL('/adminlistpaquote', {}, `${environment.PA_API_URL}`),
      data,
    );
  }

  getPersonalAccidentAggregateQuotesAndPolicies(
    data: any,
  ): Observable<ApiResponse> {
    return this.http.makePostRequest(
      `${environment.CORE_API_URL}/adminaggregate`,
      data,
    );
  }

  getLifeAggregateQuotesAndPolicies(data: any): Observable<ApiResponse> {
    return this.http.makePostRequest(
      `${environment.CORE_API_URL}/adminaggregate`,
      data,
    );
  }

  getEvacuationQuotesAndPolicies(data: any): Observable<ApiResponse> {
    return this.http.makePostRequest(
      `${environment.CORE_API_URL}/adminlistquote`,
      data,
    );
  }

  getEvacuationAggregateQuotesAndPolicies(data: any): Observable<ApiResponse> {
    return this.http.makePostRequest(
      `${environment.CORE_API_URL}/adminaggregate`,
      data,
    );
  }

  getGolfQuotesAndPolicies(data: any): Observable<ApiResponse> {
    return this.http.makePostRequest(
      `${environment.CORE_API_URL}/adminlistquote`,
      data,
    );
  }

  getGolfAggregateQuotesAndPolicies(data: any): Observable<ApiResponse> {
    return this.http.makePostRequest(
      `${environment.CORE_API_URL}/adminaggregate`,
      data,
    );
  }

  fetchUnderwritercontacts(data: any): Observable<IUnderwriterContact[]> {
    return this.http
      .makePostRequest(
        `${environment.CORE_API_URL}/getunderwritercontacts`,
        data,
      )
      .pipe(
        map((res) => {
          return res.Payload;
        }),
      );
  }

  getUnderWriterProducts(product: string): Observable<any> {
    return this.http
      .makePostRequest(
        `${environment.CORE_API_URL}/listUnderWriterProducts
    `,
        {
          product: product,
        },
      )
      .pipe(
        map((res: ApiResponse) => {
          const underwriteProducts = res.Payload;
          return underwriteProducts?.sort((a: any, b: any) =>
            a?.product.localeCompare(b?.product),
          );
        }),
      );
  }

  fetchMedicalProviders(): Observable<Underwriter[]> {
    return of([
      {
        _id: 'sasadoctor-2fe6-4ffb-9e30-46165afc5140',
        name: 'SASAhealth Limited',
      },
      {
        _id: 'checkupsmed-52ee-4bea-8698-2a1b1db6d61b',
        name: 'Checkups OP Benefit',
      },
    ]);
    // return this.http
    //   .makePostRequest(
    //     `${environment.CORE_API_URL}/listMedicalProviders`,
    //     {},
    //   )
    //   .pipe(
    //     map((res) => {
    //       return res.Payload;
    //     }),
    //   );
  }
  getUnderwritersByProduct(product: string): Observable<Underwriter[]> {
    return this.getUnderWriterProducts(product).pipe(
      map((insurer) => {
        if (!insurer) {
          return [];
        }
        if (!insurer.length) {
          return [];
        }

        const underwriteProducts = insurer[0]?.underwriters || [];
        if (this.isAdminOrThirdParty()) {
          return underwriteProducts.map((uw: any) => ({
            _id: uw.insurer,
            name: uw.underwriter,
          }));
        } else {
          const insurer = this.getSessionUser().insurer;
          return underwriteProducts
            .filter((uw: any) => {
              return uw.insurer === insurer;
            })
            .map((uw: any) => ({
              _id: uw.insurer,
              name: uw.underwriter,
            }));
        }
      }),
    );
  }

  async sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
}

export interface IUnderwriterContact {
  id: string;
  name: string;
  product: string;
  email: string;
  _id: string;
  cc?: string;
}

export interface IKeyValueCategory {
  key: string;
  value: string;
  categories: any[];
}

export interface TableFilter {
  name: string;
  label: string;
  key: string;
  value: any;
}
export interface IAudit {
  message: any;
  operation?: string;
  _date?: any;
  _timestamp: EpochTimeStamp;
  userid?: string | null;
  feduid: string | null;
  name?: string;
}
export enum AppEnums {
  ToastTypeSuccess = 'success',
  ToastTypeInfo = 'info',
  ToastTypeError = 'error',
  ToastTypeWarning = 'warning',
  RoleAdmin = 'admin',
  RoleClient = 'client',
  RoleSalesAgent = 'salesagent',
  RoleLeadGenerator = 'leadgenerator',
  RolePartner = 'partner',
  RoleFinance = 'finance',
  RoleAssessor = 'assessor',
  RoleAgent = 'agent',
  RoleBroker = 'broker',
  RoleValuer = 'valuer',
  RolePrimePartner = 'primepartner',
  RolePrimePartnerPlus = 'primepartnerplus',
  RoleCollective = 'collective',
  RolePataPesa = 'patapesa',
  RoleAssociate = 'associate',
  RoleConsultant = 'consultant',
  RoleSalesRep = 'salesrep',
  PartnerIdMUAKenya = 'mua-ke-14ac2-dc46-4edd-ae6c-cd0b4f4ae832',
  PartnerIdGA = 'ga-dbbcd171-aa7c-4405-ad9c-4f8ab9ee0003',
  PartnerIdMTEK = 'mtek-dbbcd171-aa7c-4405-ad9c-4f8ab9ee0007',
  PartnerIdMayfair = 'mayfair-dbbcd171-aa7c-4405-ad9c-4f8ab9ee0001',
  PartnerIdICEA = 'icea-ke-d171-aa7c-4405-ad9c-4f8ab9ee0023',
  PartnerIdMadison = 'madison-d171-aa7c-4405-ad9c-4f8ab9ee0034',
  PartnerIdJubilee = 'jubilee-d171-aa7c-4405-ad9c-4f8ab9ee0040',
  PartnerIdMonarch = 'monarch-ke-d171-aa7c-4405-ad9c-4f8ab9ee0025',
  PartnerIdResolution = 'resolution-d171-aa7c-4405-ad9c-4f8ab9ee0035',
  PartnerIdKorient = 'korient-d171-aa7c-4405-ad9c-4f8ab9ee0039',
  PartnerIdFirstAssurance = 'fassurance-d171-aa7c-4405-ad9c-4f8ab9ee0036',
  PartnerIdPioneer = 'pioneer-d171-aa7c-4405-ad9c-4f8ab9ee0054',
  PartnerIdBritam = 'britam-d171-aa7c-4405-ad9c-4f8ab9ee0036',
  PartnerIdOccidental = 'occidental-d171-aa7c-4405-ad9c-4f8ab9ee0037',
  PartnerIdCic = 'cic-d174-la7c-4t05-ad9c-al8ab9ee0044',
  PDF = 'PDF',
  HTML = 'HTML',
  SVG = 'SVG',
  AddunderwriterProduct = 'AddunderwriterProduct',
  MXPNL_LOGIN_SUCCESS = 'LOGIN_SUCCESS',
  MXPNL_LOGIN_FAILED = 'LOGIN_FAILED',
  MXPNL_FORGOT_PASSWORD = 'FORGOT_PASSWORD',
  MXPNL_CHANGE_PASSWORD = 'CHANGE_PASSWORD',
  MXPNL_LOGOUT = 'LOGOUT',
  MXPNL_ANONYMOUS_UID = '_ANONYMOUS_UID_',
  MTEK_PAYBILL_NUMBER = '4069593',
  PRE_ISSUED_POLICY_NUMBER = 'PRE_ISSUED_POLICY_NUMBER',
  PRODUCT_MOTOR = 'motor',
  PRODUCT_HEALTH = 'health',
  PRODUCT_TRAVEL = 'travel',
  PRODUCT_PA = 'personalaccident',
  PRODUCT_GOLF = 'golf',
  PRODUCT_DOMESTIC = 'domestic',
  PRODUCT_EVACUATION = 'evacuation',
  PRODUCT_LASTEXPENSE = 'lastexpense',
  PRODUCT_TELEMEDICINE = 'telemedicine',
  PRODUCT_OTHER = 'other',
  PRODUCT_GADGET = 'gadget',
  PRODUCT_LIFE = 'life',
  PRODUCT_UNISURE = 'unisure',
  PRODUCT_RESCUE = 'rescue',
  PRODUCT_CREDIT_LIFE = 'credit_life',
  PRODUCT_MARINE = 'marine',
  PRODUCT_CANCER = 'cancer',
  PRODUCT_WIBA = 'wiba',
  PRODUCT_CONTRACTORS_ALL_RISK = 'car',
  PRODUCT_PROFESSIONAL_INDEMNITY = 'professional_indemnity',
  PRODUCT_EMPLOYERS_LIABILITY = 'employers_liability',
  PRODUCT_COVID = 'covid',
  PRODUCT_FIRE_AND_ALLIED_PERILS = 'fire_and_allied_perils',
  PRODUCT_CONTRACTUAL_LIABILITY = 'contractual_liability',
  PRODUCT_INCOME_DRAW_DOWN = 'income_draw_down',
  PRODUCT_BONDS = 'bonds',
  PRODUCT_HOSPITAL_CASH = 'hospital_cash',

  PartnerTrustgro = 'trustgro-e1de-4920-89b2-a317f7628c4a',
  PartnerLuminous = 'luminous_matatu_sacco-49e9-b9ac-f3c9e3ff52a1',
  PartnerEmbassava = 'embassava_matatu_sacco-4dfa-8182-538b5aa204ee',
  PartnerAstrabel = 'astrabel_matatu_sacco-4520-9c82-e1c38f0a8e68',
  PartnerKyosk = 'kyosk-fdcf-43c5-b0a2-9e0ad6c2027b',
  PartnerAlliesCapital = 'allies-capital-258e-43eb-8014-8866b16ee657',
  PartnerBuySimu = 'buysimu-db5943ae-4330-41e5-8376-455f98b7f5b5',
  PartnerBuuPass = 'buupass-e3e0442f-e363-4425-bb22-2fa10a9ba84d',
  PartnerMyDawa = 'mydawa-9342a596-2d0b-45ff-8b67-f752fff4682c',

  TEST_POLICY_MEDICAL = '66fa747245109936430dde2c',
}
export interface IPagination {
  limit?: number;
  skip?: number;
  sort?: any;
  $limit?: number;
  $skip?: number;
  $sort?: any;
}
