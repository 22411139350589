import { Component, Input, model } from '@angular/core';
import { ButtonComponent } from '../buttons/button/button.component';
import { ExternalToast, NgxSonnerToaster, toast } from 'ngx-sonner';
import { BasicCardComponent } from '../cards/basic-card/basic-card.component';

@Component({
  selector: 'mtek-confirm-toast',
  standalone: true,
  imports: [ButtonComponent, BasicCardComponent],
  templateUrl: './confirm-toast.component.html',
  styleUrl: './confirm-toast.component.css',
})
export class ConfirmToastComponent {
  @Input() onConfirm: () => any = () => {};
  @Input() onCancel: () => any = () => {};
  @Input({ required: true }) toast!: typeof toast;

  confirmTitle = model<string>($localize`Yes, Proceed`);
  cancelTitle = model<string>($localize`No, Go back`);
  title = model<string>($localize`Confirm`);

  body = model.required<string>();

  confirm() {
    this.onConfirm();
    this.toast.dismiss();
  }

  cancel() {
    this.onCancel();
    this.toast.dismiss();
  }
}
