import { inject, Injectable, signal } from '@angular/core';
import { LocalStorageService } from '../utilities/local-storage.service';
import {
  MultiFactorInfo,
  MultiFactorResolver,
  TotpSecret,
  User,
} from 'firebase/auth';
import { Locale } from '../services/layout.service';
import { ThirdParty } from '../services/third-parties.service';
import { Underwriter } from '../services/underwriters.service';
import { Product } from '../services/products.service';
import { User as MtekUser } from '../pages/users/users.service';

@Injectable({
  providedIn: 'root',
})
export class State {
  private key = 'store';
  private _localStorageService = inject(LocalStorageService);

  store = signal<StoreType>(initialStoreState);

  constructor() {
    const localStore = this._localStorageService.getItem<StoreType>(this.key);
    if (localStore) this.store.set(localStore);
  }
}

interface DashboardStore {
  startDate: number | null;
  endDate: number | null;
}

export interface StoreType {
  loading: boolean;
  error?: string;
  auth: Auth;
  tp?: ThirdParty | null;
  apiUser: ApiUser | null;
  locale: Locale | null;
  tenant: ThirdParty | null;
  lastUrl: string | null;
  darkMode: boolean;
  mfa: {
    currentTotpSecret: TotpSecret | null;
    enrolledFactors: MultiFactorInfo[];
    mfaResolver: MultiFactorResolver | null;
    hasOtp: boolean;
    currentUser?: string;
  };
  dashboard: DashboardStore;
  app: AppState;
}

export interface Tenant {
  id: string | null;
  host: string | null;
  displayName: string | null;
}

export type ApiUser = MtekUser;

export interface Auth {
  user: User | null;
  idToken: string | null;
  accessToken: string | null;
  waitingForGoogle: boolean;
  lastApiResponse: string | number | null;
  isAuthenticated: boolean;
}

export interface Theme {
  darkMode?: boolean;
  primaryColor: string;
  secondaryColor: string;
  onPrimaryColor: string;
  onSecondaryColor: string;
  infoColor: string;
  warningColor: string;
  successColor: string;
  dangerColor: string;
  onInfoColor: string;
  onWarningColor: string;
  onSuccessColor: string;
  onDangerColor: string;
  logoUrl?: string;
  faviconUrl?: string;
  coverImageUrl?: string;
}

const initialStoreState: StoreType = {
  loading: false,
  apiUser: null,
  locale: null,
  tp: null,
  lastUrl: '/',
  darkMode: false,
  auth: {
    user: null,
    idToken: null,
    accessToken: null,
    waitingForGoogle: false,
    lastApiResponse: '',
    isAuthenticated: false,
  },
  tenant: null,
  mfa: {
    currentTotpSecret: null,
    enrolledFactors: [],
    mfaResolver: null,
    hasOtp: false,
  },
  dashboard: {
    startDate: null,
    endDate: null,
  },
  app: {
    products: [],
    underwriters: [],
  },
};

export interface Toast {
  type: 'success' | 'primary' | 'danger' | 'info' | 'warning';
  message: string;
  duration?: number;
  position?:
    | 'top'
    | 'bottom'
    | 'left'
    | 'right'
    | 'center'
    | 'top-left'
    | 'top-right'
    | 'bottom-left'
    | 'bottom-right';
}

export interface AppState {
  underwriters: Underwriter[];
  products: Product[];
}
