import { inject, Injectable } from '@angular/core';
import { State, StoreType } from '../store';
import color from 'color';

import colors from 'tailwindcss/colors';
import { Partner } from './partners.service';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  state: State = inject(State);

  toggleDarkMode() {
    this.state.store.update((store: StoreType) => {
      return {
        ...store,
        darkMode: !store.darkMode,
      };
    });
    this.setDarkClass();
  }

  setDarkClass() {
    if (this.state.store().darkMode) {
      console.log('setting dark mode');
      document.body.classList.add('dark');
    } else {
      console.log('removing dark mode');
      document.body.classList.remove('dark');
    }
  }

  setTheme() {
    this.setDarkClass();
    const tenant: Partner | null = this.state.store().tenant;
    if (tenant) {
      if (tenant.primaryColor) {
        document.documentElement.style.setProperty(
          '--color-primary',
          tenant.primaryColor,
        );
      }

      if (tenant.secondaryColor) {
        document.documentElement.style.setProperty(
          '--color-secondary',
          tenant.secondaryColor,
        );
      }

      if (tenant.infoColor) {
        document.documentElement.style.setProperty(
          '--color-info',
          tenant.infoColor,
        );
      }

      if (tenant.warningColor) {
        document.documentElement.style.setProperty(
          '--color-warning',
          tenant.warningColor,
        );
      }

      if (tenant.successColor) {
        document.documentElement.style.setProperty(
          '--color-success',
          tenant.successColor,
        );
      }

      if (tenant.dangerColor) {
        document.documentElement.style.setProperty(
          '--color-danger',
          tenant.dangerColor,
        );
      }

      if (tenant.onPrimaryColor) {
        document.documentElement.style.setProperty(
          '--color-on-primary',
          tenant.onPrimaryColor,
        );
      }

      if (tenant.onSecondaryColor) {
        document.documentElement.style.setProperty(
          '--color-on-secondary',
          tenant.onSecondaryColor,
        );
      }

      if (tenant.onInfoColor) {
        document.documentElement.style.setProperty(
          '--color-on-info',
          tenant.onInfoColor,
        );
      }

      if (tenant.onWarningColor) {
        document.documentElement.style.setProperty(
          '--color-on-warning',
          tenant.onWarningColor,
        );
      }

      if (tenant.onSuccessColor) {
        document.documentElement.style.setProperty(
          '--color-on-success',
          tenant.onSuccessColor,
        );
      }

      if (tenant.onDangerColor) {
        document.documentElement.style.setProperty(
          '--color-on-danger',
          tenant.onDangerColor,
        );
      }

      document
        .getElementById('favicon')
        ?.setAttribute('href', this.state.store().tenant?.faviconUrl || '');
    }
  }

  getThemeColor(
    name:
      | 'primary'
      | 'secondary'
      | 'info'
      | 'warning'
      | 'success'
      | 'danger'
      | 'on-primary'
      | 'on-secondary'
      | 'on-info'
      | 'on-warning'
      | 'on-success'
      | 'on-danger',
    level: 50 | 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900 | 950 = 500,
  ) {
    const theme: Partner | null = this.state.store().tenant;
    const map = {
      primary: theme?.primaryColor,
      secondary: theme?.secondaryColor,
      info: theme?.infoColor,
      warning: theme?.warningColor,
      success: theme?.successColor,
      danger: theme?.dangerColor,
      'on-primary': theme?.onPrimaryColor,
      'on-secondary': theme?.onSecondaryColor,
      'on-info': theme?.onInfoColor,
      'on-warning': theme?.onWarningColor,
      'on-success': theme?.onSuccessColor,
      'on-danger': theme?.onDangerColor,
    } as Record<typeof name, any>;
    return this.getPalette(map[name], level);
  }

  getPalette(
    colorName: string,
    level: 50 | 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900 | 950 = 500,
  ) {
    try {
      const dL: number = level === 500 ? 0 : Math.abs(500 - level) / 500 - 0.1;
      return level >= 500
        ? color(colorName).darken(dL).hex()
        : color(colorName).lighten(dL).hex();
    } catch (error: any) {
      console.log(error);
      console.log(`The error occurred while trying to parse ${colorName}`);
      return colorName;
    }
  }
  generateChartColors() {
    const repo: string[] = [];
    const theme = this.state.store().tenant;
    console.log(`Trying to generate colors for tenant: ${theme?.name}`);
    const map = {
      primary:
        theme?.primaryColor ||
        document.documentElement.style.getPropertyValue('--color-primary'),
      secondary:
        theme?.secondaryColor ||
        document.documentElement.style.getPropertyValue('--color-secondary'),
      info:
        theme?.infoColor ||
        document.documentElement.style.getPropertyValue('--color-info'),
      warning:
        theme?.warningColor ||
        document.documentElement.style.getPropertyValue('--color-warning'),
      success:
        theme?.successColor ||
        document.documentElement.style.getPropertyValue('--color-success'),
      danger:
        theme?.dangerColor ||
        document.documentElement.style.getPropertyValue('--color-danger'),
    };
    console.log(`Generated map:`, map);
    const iteration: (
      | 50
      | 100
      | 200
      | 300
      | 400
      | 500
      | 600
      | 700
      | 800
      | 900
      | 950
    )[] = [500, 300, 600, 400, 700, 300];
    // iterate and push palettes
    iteration.forEach((level) => {
      repo.push(this.getPalette(map.primary || '#36c2d7', level));
      repo.push(this.getPalette(map.secondary || '#167f9f', level));
      repo.push(this.getPalette(map.success || '#22c55e', level));
      repo.push(this.getPalette(map.warning || '#eab308', level));
      repo.push(this.getPalette(map.info || '#0ea5e9', level));
      repo.push(this.getPalette(map.danger || '#ef4444', level));
      repo.push(this.getPalette(colors.purple[500], level));
      repo.push(this.getPalette(colors.sky[500], level));
      repo.push(this.getPalette(colors.amber[500], level));
      repo.push(this.getPalette(colors.blue[500], level));
      repo.push(this.getPalette(colors.emerald[500], level));
      repo.push(this.getPalette(colors.indigo[500], level));
      repo.push(this.getPalette(colors.zinc[500], level));
      repo.push(this.getPalette(colors.stone[500], level));
      repo.push(this.getPalette(colors.teal[500], level));
    });
    return repo;
  }
}
