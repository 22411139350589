import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'mtek-basic-card,[mtekBasicCard]',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './basic-card.component.html',
  styleUrl: './basic-card.component.css',
})
export class BasicCardComponent {
  @Input() overrideClasses = 'bg-white dark:bg-gray-800 dark:text-gray-500';
  @Input() themeColor: ThemeColor = 'headless';

  get cardClasses() {
    const themeClasses = {
      white:
        'shadow rounded-2xl text-gray-600 bg-white dark:bg-gray-900 dark:text-gray-500',
      primary:
        'shadow rounded-2xl text-on-primary bg-primary dark:border-l-4 dark:border-primary dark:bg-gray-700 dark:text-primary-200',
      secondary:
        'shadow rounded-2xl text-on-secondary bg-secondary dark:border-l-4 dark:border-secondary dark:bg-gray-700 dark:text-secondary-50',
      success:
        'shadow rounded-2xl text-on-success bg-success dark:border-l-4 dark:border-success dark:bg-gray-700 dark:text-success-50',
      danger:
        'shadow rounded-2xl bg-danger text-on-danger dark:border-l-4 dark:border-danger dark:bg-gray-700 dark:text-danger-50',
      warning:
        'shadow rounded-2xl text-on-warning bg-warning dark:border-l-4 dark:border-warning dark:bg-gray-700 dark:text-warning-50',
      info: 'shadow rounded-2xl text-on-info bg-info dark:border-l-4 dark:border-info dark:bg-gray-700 dark:text-info-50',
      headless: '',
    };

    return `${themeClasses[this.themeColor]}`;
  }

  mergedClasses(): string {
    const cardClasses = this.cardClasses.split(' ');
    const mergedClasses = [...this.overrideClasses, ...cardClasses];
    const uniqueMerged = Array.from(new Set(mergedClasses)); // Remove duplicates
    return uniqueMerged.join(' ');
  }
}

export type ThemeColor =
  | 'headless'
  | 'white'
  | 'primary'
  | 'secondary'
  | 'success'
  | 'danger'
  | 'warning'
  | 'info';
