import { inject, Injectable } from '@angular/core';
import { ApiResponse, HttpService } from '../../services/http.service';
import { AppService } from '../../app.service';
import { apiURL, Endpoints } from '../../configs/http.config';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  async fetchUserDetails(feduid: string) {
    return new Promise<User>((resolve, reject) => {
      this.http
        .post(apiURL(Endpoints.getUserDetails), { feduid })
        .then((res: ApiResponse) => {
          resolve(res.Payload as User);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  private http = inject(HttpService);
  app = inject(AppService);

  async fetchUsers(params: Record<any, any> = {}): Promise<User[]> {
    return new Promise<User[]>((resolve, reject) => {
      this.http
        .post(apiURL(Endpoints.listUsers), params)
        .then((res: ApiResponse) => {
          resolve(res.Payload as User[]);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  getUserRolesList(record?: User | null) {
    if (!record) return [];
    const roles = record.roles as Record<any, any>[];
    if (!roles?.length) return [];

    const keys = roles.map((val) => Object.keys(val)[0]);
    const values = roles.map((val) => Object.values(val)[0]);
    const rolesList = keys.map((key, index) => {
      return { name: key, value: values[index] };
    }) as { name: string; value: string }[];
    return rolesList.filter((role) => role.value).map((role) => role.name);
  }

  getUserAccountsList(): { name: string; value: string }[] {
    return [
      { name: 'Assessors', value: 'assessors' },
      { name: 'Agencies', value: 'agencies' },
      { name: 'Valuers', value: 'valuers' },
      { name: 'Client', value: 'client' },
      { name: 'County Reps', value: 'countyRep' },
      { name: 'Collective', value: 'collective' },
      { name: 'Patapesa', value: 'patapesa' },
      { name: 'Prime Partner', value: 'primepartner' },
      { name: 'Prime Partner Plus', value: 'primepartnerplus' },
      { name: 'Associate', value: 'associate' },
      { name: 'Agent', value: 'agent' },
      { name: 'Sales Representatives', value: 'salesrep' },
      { name: 'Administrators', value: 'admin' },
    ].sort((a, b) => {
      return a.name.localeCompare(b.name);
    });
  }
}

export interface SignupStatsRecord extends Record<any, any> {
  admin?: number;
  agent?: number;
  assessor?: number;
  associate?: number;
  broker?: number;
  client?: number;
  collective?: number;
  consultant?: number;
  leadgenerator?: number;
  partner?: number;
  patapesa?: number;
  primepartner?: number;
  primepartnerplus?: number;
  salesagent?: number;
  salesrep?: number;
  valuer?: number;
}

export enum RoleEnum {
  admin,
  agent,
  assessor,
  associate,
  broker,
  client,
  collective,
  consultant,
  leadgenerator,
  partner,
  patapesa,
  primepartner,
  primepartnerplus,
  salesagent,
  salesrep,
  valuer,
}
export interface UserStatsRecord extends Record<any, any> {
  admin?: number;
  agent?: number;
  assessor?: number;
  associate?: number;
  broker?: number;
  client?: number;
  collective?: number;
  consultant?: number;
  leadgenerator?: number;
  partner?: number;
  patapesa?: number;
  primepartner?: number;
  primepartnerplus?: number;
  salesagent?: number;
  salesrep?: number;
  valuer?: number;
}

export interface User {
  mfa?: boolean;
  _id?: any;
  feduid?: string;
  thirdPartyId?: string;
  userName?: string;
  email?: string;
  verified?: boolean;
  roles?: any;
  image_byte_strings?: string;
  phoneNumber?: string;
  idNumber?: string;
  driverLicense?: string;
  insurer?: string;
  partner?: string;
  idToken?: string;
  kraPin?: string;
  idPhoto?: string;
  driversLicence?: string;
  postalAddress?: string;
  disabled?: boolean;
  address?: string;
  agencyid?: string;
  city?: string;
  countryofresidence?: string;
  dob?: string;
  id_number?: string;
  kra?: string;
  maritalstatus?: string;
  name?: string;
  nationality?: string;
  occupation?: string;
  othernames?: string;
  phone?: string;
  physicaladdress?: string;
  postaladdress?: string;
  postalcode?: string;
  surname?: string;
  title?: string;
  clientid?: string;
  _date?: any;
  _timestamp?: number;
  _udate?: any;
  _utimestamp?: number;
  nokLocation?: string;
  provider?: string;
  adminNumber?: string;
  NTSA_REASON?: string;
  NTSA_VALID?: string;
  street?: string;
  idImageByteStrings?: string;
  nokRelationship?: string;
  ntsaDriverLicense?: string;
  NTSA?: string;
  area?: string;
  ageRange?: string;
  devicetoken?: string;
  kinName?: string;
  notificationToken?: string;
  referralCode?: string;
  lastName?: string;
  noOfYears?: string;
  nokPostalAddress?: string;
  state?: string;
  nokPhoneNo?: string;
  nokId_Passport?: string;
  passportNumber?: string;
  idPhotoFront?: string;
  dependents?: string;
  ntsaDlExpiry?: string;
  referral?: string;
  married?: string;
  signature?: string;
  home?: string;
  idPhotoBack?: string;
  certificate?: string;
  workPhone?: string;
  promo_issued?: string;
  ntsaName?: string;
  agents?: string;
  applicant2?: string;
  institution?: string;
  ntsaIdNumber?: string;
  kraPhoto?: string;
  logbookphoto?: string;
  dlphoto?: string;
  profileImageByteStrings?: string;
  country?: string;
  firstName?: string;
  undewriter?: string;
  promo?: string;
  countyCode?: string;
  countyRep?: boolean;
  gender?: string;
  middleName?: string;
  driversExperience?: string;
  relationship?: string;
  physicalAddress?: string;
  applicant1?: string;
  postalCode?: string;
  nokName?: string;
  mobileBanking?: string;
  validDl?: string;
  homePhone?: string;
  postalAddress1?: string;
  location?: string;
  education?: string;
  kinTelephone?: string;
  agentCode?: string;
  employmentStatus?: string;
  homeOwnershipStatus?: string;
  profileImage?: string;
  passportPhoto?: string;
  id?: string;
  postalAddress2?: string;
  nokPostalCode?: string;
  residence?: string;
  nokDob?: string;
  placeOfBirth?: string;
  employer?: string;
  timestamp?: string;
  maritalStatus?: string;
  promoIssued?: any;
  adminDocuments?: any;
  bimaProfile?: {
    totalPoints: number;
    balance: number;
    redeemed: number;
    referrerCode: string;
    referralCode: string;
    referrals: number;
  };
  monthlyIncome?: string;
  spouseOrKids?: string;
  ownACar?: boolean;
  ownOrRent?: string;

  businessCertImage?: string;
  businessCertNo?: string;
  copImage?: string;
  copNo?: string;
  kraPinImage?: string;
  nationalIdImageBack?: string;
  nationalIdImageFront?: string;
  nationalIdNo?: string;
  principleFeduid?: string;
  principleProfile?: any;
  principle_id?: string;
  underwriters?: any;
  salesAgentProfile?: any;
  ModuleID?: string;
  termsAcceptedTimeStamp?: number;
  termsAccepted?: boolean;
}
