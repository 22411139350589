import { Injectable, Type } from '@angular/core';
import { ExternalToast, toast } from 'ngx-sonner';
import { HttpErrorResponse } from '@angular/common/http';
import { ApiResponse } from './http.service';
import { ConfirmToastComponent } from '../library/components/confirm-toast/confirm-toast.component';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  public readonly toast = toast;

  success(message: string, duration?: number) {
    this.toast.success(message, { duration } as ExternalToast);
  }
  error(message: string, duration?: number) {
    this.toast.error(message, { duration });
  }
  info(message: string, duration?: number) {
    this.toast.info(message, { duration });
  }
  warning(message: string, duration?: number) {
    this.toast.warning(message, { duration });
  }
  confirm(
    message: string,
    title: string,
    callback: (e: Event) => void = (e) => {},
  ) {
    this.toast.custom(ConfirmToastComponent, {
      position: 'top-center',
      dismissible: true,
      unstyled: false,
      closeButton: true,
      important: true,
      duration: 30000,
      class: '!bg-transparent text-on-danger !px-0 !py-0',
      // classes: {
      //   title: 'bg-primary text-on-primary',
      //   description: 'text-on-primary',
      //   default: 'bg-primary-100 text-primary dark:bg-gray-800 text-primary-50',
      // },
      componentProps: {
        body: message,
        title: title,
        onConfirm: callback,
        toast: this.toast,
      },
    });
  }
  custom(component: Type<unknown>, callback: (e: Event) => void = (e) => {}) {
    this.toast.custom(component, {
      dismissible: false,
      action: { label: 'Yes', onClick: (e) => callback(e) },
    });
  }
  apiError(error: HttpErrorResponse | ApiResponse | string | any): string {
    const msg =
      typeof error === 'string'
        ? error
        : error?.Payload ||
          error?.message ||
          error?.error ||
          error?.errors ||
          `The request failed with status ${error?.status}`;
    this.error(msg);
    return msg;
  }
}
