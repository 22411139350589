import { effect, inject, Injectable } from '@angular/core';
import { LocalStorageService } from '../utilities/local-storage.service';
import { State } from './state';
import { apiURL, Endpoints } from '../configs/http.config';
import { ThemeService } from '../services/theme.service';
import dayjs from 'dayjs';
import { ApiResponse, HttpService } from '../services/http.service';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class Actions {
  private key = 'store';
  private _themeService = inject(ThemeService);
  private _localStorageService = inject(LocalStorageService);
  private _stateService = inject(State);
  private http = inject(HttpService);

  constructor() {
    effect(() => {
      this._localStorageService.setItem(this.key, this._stateService.store());
    });
  }

  // with side effect because this is with asynchronous call
  async fetchThemes() {
    // Parse current url:
    return new Promise((resolve, reject) => {
      const currentHost = window.location.hostname;
      console.log(`Current Host is ${currentHost}: Fetching theme...`);
      try {
        this.fetchCurrentTenant(currentHost)
          .then((res) => resolve(res))
          .catch((err) => {
            console.log(err);
            console.log(`Let's try the fallback theme:`);
            this.fetchFallbackTenant()
              .then((res) => resolve(res))
              .catch((err) => reject(err));
          });
      } catch (e) {
        console.log(`Initial tenant request encountered an error:`, e);
        try {
          console.log(`Let's try the fallback theme:`);
          this.fetchFallbackTenant()
            .then((res) => resolve(res))
            .catch((err) => reject(err));
        } catch (error) {
          console.log('Final Error');
          console.log(error);
          reject(error);
        }
      }
    });
  }
  async fetchCurrentTenant(currentHost: string) {
    return new Promise<void>((resolve, reject) => {
      let params: any = {
        tenantHost: currentHost,
      };
      const currentUser = this._stateService.store().apiUser;
      if (currentUser) {
        if (currentUser.thirdPartyId) {
          console.log(
            'THere is a current user: We will fetch tenant by third party id instead.',
            currentUser.ModuleID,
          );
          params = {
            _id: currentUser.thirdPartyId,
          };
        }
      }
      this.http
        .post(apiURL(Endpoints.listThirdParties), params, {
          apiKey: environment.APP_API_KEY,
        })
        .then((res: ApiResponse) => {
          if (res.Status !== 200) {
            reject(res.Payload);
            return;
          }
          if (!res.Payload) {
            reject('No tenant payload found');
            return;
          }
          if (res.Payload.length) {
            this._stateService.store.update((state) => {
              return {
                ...state,
                tenant: res.Payload[0] || state.tenant || null,
              };
            });
          } else {
            reject('No tenant matches the current host.');
          }
          this._themeService.setTheme();
          resolve(res.Payload[0]);
        })
        .catch((e) => {
          reject(e);
        });
    });
  }
  async fetchFallbackTenant() {
    console.log('Fetching fallback tenant');
    return new Promise((resolve, reject) => {
      this.http
        .post(
          apiURL(Endpoints.listThirdParties),
          {
            _id: environment.APP_DEFAULT_TP,
          },
          {
            apiKey: environment.APP_API_KEY,
          },
        )
        .then((res: ApiResponse) => {
          this._stateService.store.update((state) => {
            return {
              ...state,
              tenant: res.Payload[0] || state.tenant || null,
            };
          });
          this._themeService.setTheme();
          resolve(true);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  /**
   * @deprecated use themeService.setTheme() instead
   */
  public setTheme() {
    this._themeService.setTheme();
  }

  public enableLoading() {
    this._stateService.store.update((state) => {
      state.loading = true;
      state.error = '';
      return { ...state };
    });
  }

  public disableLoading() {
    this._stateService.store.update((state) => {
      state.loading = false;
      return { ...state };
    });
  }
  setDateFilters() {
    this._stateService.store.update((state) => {
      state.dashboard.startDate =
        state.dashboard.startDate ??
        dayjs().subtract(1, 'month').toDate().getTime();
      state.dashboard.endDate =
        state.dashboard.endDate ?? dayjs().toDate().getTime();
      return state;
    });
  }

  private setError(message: string) {
    this._stateService.store.update((state) => {
      state.error = message;
      return { ...state };
    });
  }
}
